import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import impEleThumb from "../assets/images/impElettrici_thumb.jpg";
import facManThumb from "../assets/images/facManagement_thumb.jpg";
import cucThumb from "../assets/images/cuc_thumb.jpg";
import chiSiamoHeader from "../assets/images/chiSiamo_header.jpg";
import brochure from "../assets/images/brochureGiuvi_2021.pdf";

const stile = {
  bottone: {
    display: "block",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
    margin: "0 auto",
    display: "table-cell",
    verticalAlign: "middle",
    marginBottom: 20,
    padding: 30,
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    textAlign: "center",
    textTransform: "uppercase",
  },
  tabella: {},
  imgCategoria: {
    width: "100%",
  },
  ulCosa: {
    display: "flex",
    justifyContent: "space-around",
  },
};

class Chi_siamo extends React.Component {
  render() {
    return (
      <Layout>
        <div id="main">
          <section id="content" className="main">
            <span className="image main">
              <img src={chiSiamoHeader} alt="Chi Siamo - Giuvi srl" />
            </span>

            <h2>La nostra Storia</h2>
            <p>
              Giuvi srl è un'affermata realtà aziendale che opera sul territorio
              nazionale dal 2012.
              <br />
              <br />
              L'azienda nasce con l'obiettivo di sviluppare impiantistica civile
              ed industriale dalla fase di progettazione alla fase di
              realizzazione e successiva manutenzione.
              <br />
              <br />
              La nostra progressiva e stabile crescita è basata sui seguenti
              principi:
              <ul>
                <li>Massima attenzione alle esigenze del cliente</li>
                <li>
                  Ricerca delle soluzioni più idonee per il singolo problema
                </li>
                <li>
                  attenta selezione di prodotti e componenti presenti sul
                  mercato con la garanzia di offrire le migliori soluzioni
                  tecniche ed economiche
                </li>
              </ul>
              Nel corso del tempo grazie alle opportunità ed alla sempre più
              vasta richiesta di integrazione l'azienda si è strutturata per
              sviluppare nuove attività, quali la gestione di impianti meccanici
              e tecnologici, la ristrutturazione edile e il food service.
              <br />
              L’idea è piaciuta sino al punto che oggi siamo in grado di
              assistere il cliente con servizi di global service e ci
              presentiamo al mercato nella veste di facility management.
            </p>
          </section>
          <section className="main special">
            <header className="major">
              <h2>DI COSA CI OCCUPIAMO</h2>
            </header>
            <ul className="features" style={stile.ulCosa}>
              <li>
                <span className="image">
                  <Link to="/impiantiElettrici">
                    <img src={impEleThumb} alt="" style={stile.imgCategoria} />
                    IMPIANTI ELETTRICI
                  </Link>
                </span>
              </li>
              <li>
                <span className="image">
                  <Link to="/facilityManagement">
                    <img src={facManThumb} alt="" style={stile.imgCategoria} />
                    FACILITY MANAGEMENT
                  </Link>
                </span>
              </li>
              <li>
                <span className="image">
                  <Link to="/equipmentCucine">
                    <img src={cucThumb} alt="" style={stile.imgCategoria} />
                    EQUIPMENT CUCINE
                  </Link>
                </span>
              </li>
            </ul>
          </section>
          <section className="main">
            <p>
              Tra i nostri partner selezioniamo le aziende leader di mercato,
              tutto questo per essere in grado di garantire al cliente la
              massima qualità disponibile sul mercato e una garanzia di durata
              dei materiali utilizzati. <br />
              <br />
              Le procedure e le fasi operative sono il fulcro del nostro lavoro:
              la consulenza tecnica, il sopralluogo preliminare, la
              preventivazione, la progettazione, la realizzazione, il collaudo e
              il rilascio della dichiarazione di conformità secondo le normative
              vigenti.
            </p>
          </section>
          <section className="main special">
            <div>
              <span className="button">
                <a href={brochure} download>
                  Scarica la nostra Brochure
                </a>
              </span>{" "}
              <br /> <br />
              <Link to="/" className="button" style={{ margin: "0 auto" }}>
                Torna alla Home
              </Link>
            </div>
          </section>
        </div>
      </Layout>
    );
  }
}

export default Chi_siamo;
